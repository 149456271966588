import React from "react"
import NewsletterSectionStyle from "./NewsletterSectionStyle.module.styl"
import {
  faAngleRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Video from "../../assets/images/Clouds.mp4"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import VisibilitySensor from "react-visibility-sensor"
import SplitText from "react-pose-text"
import isValidEmail from "./../../common/emailValidator/EmailValidator"

config.autoAddCss = false

class NewsletterSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
      error: "",
      inf_field_Email: "",
    }
  }
  onChange = () => {
    this.setState({ isVisible: !this.state.isVisible })
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  submitForm = () => {
    const { inf_field_Email } = this.state

    if (!isValidEmail(inf_field_Email)) {
      this.setState({ error: "Wrong email address !" })
      return
    }
    document
      .getElementById("inf_form_5b3ed8a192f0ed69fafda39fce1a8401")
      .submit()
  }
  render() {
    const charPoses = {
      exit: { opacity: 0, y: 0 },
      enter: {
        opacity: 1,
        y: 0,
        delay: ({ charIndex }) => charIndex * 50,
      },
    }
    const { isVisible, error, inf_field_Email } = this.state

    return (
      <VisibilitySensor
        minTopValue={200}
        partialVisibility={true}
        onChange={this.onChange}
      >
        <section
          id="newsletter"
          className={NewsletterSectionStyle.newsletter_section}
        >
          <video
            className={NewsletterSectionStyle.image}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={Video} type="video/mp4" />
          </video>

          <div className={NewsletterSectionStyle.content_holder}>
            {isVisible === false ? (
              <h2>
                <SplitText
                  initialPose="exit"
                  pose={"enter"}
                  charPoses={charPoses}
                >
                  Get in touch.
                </SplitText>
              </h2>
            ) : (
              <h2>Get in touch.</h2>
            )}
            {isVisible === false ? (
              <h3>
                <SplitText
                  initialPose="exit"
                  pose={"enter"}
                  charPoses={charPoses}
                >
                  Let us know about your project
                </SplitText>
              </h3>
            ) : (
              <h3>Let us know about your project</h3>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "13px",
                width: "100%",
                maxWidth: "390px",
              }}
            >
              <a
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  height: "56px",
                  border: "solid 1px #000",
                  background: "transparent",
                  fontSize: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  gap: "17px",
                  color: "#000",
                  paddingLeft: "13px",
                  fontFamily: "Helvetica",
                }}
                className={NewsletterSectionStyle.button}
                href="tel:+359 888 088 750"
              >
                <FontAwesomeIcon icon={faPhone} style /> +359 888 088 750
              </a>
              <a
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  height: "56px",
                  background: "#ff0000",
                  fontSize: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  gap: "17px",
                  color: "#fff",
                  paddingLeft: "13px",
                  fontFamily: "HelveticaBold",
                  overflow: "hidden",
                }}
                href="mailto:contact@stsolutions.bg"
                className={NewsletterSectionStyle.button}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                contact@stsolutions.bg
              </a>
            </div>
            {/* <form
              accept-charset="UTF-8"
              action="https://zp489.infusionsoft.com/app/form/process/5b3ed8a192f0ed69fafda39fce1a8401"
              className="infusion-form"
              id="inf_form_5b3ed8a192f0ed69fafda39fce1a8401"
              method="POST"
            >
              <input
                name="inf_form_xid"
                type="hidden"
                value="5b3ed8a192f0ed69fafda39fce1a8401"
              />
              <input
                name="inf_form_name"
                type="hidden"
                value="Newsletter HookPoint"
              />
              <input
                name="infusionsoft_version"
                type="hidden"
                value="1.70.0.262866"
              />
              <div className="infusion-field">
                <input
                  required
                  className="infusion-field-input"
                  id="inf_field_Email"
                  name="inf_field_Email"
                  placeholder="Your Email"
                  type="email"
                  onChange={this.handleInput}
                  value={inf_field_Email}
                />
              </div>
              <div className="infusion-submit">
                <button onClick={this.submitForm} type="submit">
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </form> */}
            {error && <p className={NewsletterSectionStyle.error}>{error}</p>}
            <script
              type="text/javascript"
              src="https://zp489.infusionsoft.app/app/webTracking/getTrackingCode"
            />
            <script
              type="text/javascript"
              src="https://zp489.infusionsoft.com/app/timezone/timezoneInputJs?xid=5b3ed8a192f0ed69fafda39fce1a8401"
            />
          </div>
        </section>
      </VisibilitySensor>
    )
  }
}

export default NewsletterSection
